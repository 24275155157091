/*
 * SPDX-FileCopyrightText: 2017 Roberto MF (Roboe)
 *
 * SPDX-License-Identifier: GPL-3.0-or-later
 */

.pc-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pc-main--section {
  padding: 1rem;
  margin-bottom: 2em;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.pc-main--section a {
  color: var(--main-link-color);
  text-decoration: none;
}

.pc-main--section p {
  width: 100%;
  line-height: 1.65em;
  text-align: justify;
}

.pc-main--section_intro {
  margin-bottom: 0;
}
