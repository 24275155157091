/*
 * SPDX-FileCopyrightText: 2023 Roberto MF (Roboe)
 *
 * SPDX-License-Identifier: GPL-3.0-or-later
 */

:root {
  --brown-50: #efebe9;
  --brown-100: #d7ccc8;
  --brown-200: #bcaaa4;
  --brown-300: #a1887f;
  --brown-400: #8d6e63;
  --brown-500: #795548;
  --brown-600: #6d4c41;
  --brown-700: #5d4037;
  --brown-800: #4e342e;
  --brown-900: #3e2723;

  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;

  --green-50: #e8f5e9;
  --green-100: #c8e6c9;
  --green-200: #a5d6a7;
  --green-300: #81c784;
  --green-400: #66bb6a;
  --green-500: #4caf50;
  --green-600: #43a047;
  --green-700: #388e3c;
  --green-800: #2e7d32;
  --green-900: #1b5e20;
  --green-A100: #b9f6ca;
  --green-A200: #69f0ae;
  --green-A400: #00e676;
  --green-A700: #00c853;

  --deep-orange-50: #fbe9e7;
  --deep-orange-100: #ffccbc;
  --deep-orange-200: #ffab91;
  --deep-orange-300: #ff8a65;
  --deep-orange-400: #ff7043;
  --deep-orange-500: #ff5722;
  --deep-orange-600: #f4511e;
  --deep-orange-700: #e64a19;
  --deep-orange-800: #d84315;
  --deep-orange-900: #bf360c;
  --deep-orange-A100: #ff9e80;
  --deep-orange-A200: #ff6e40;
  --deep-orange-A400: #ff3d00;
  --deep-orange-A700: #dd2c00;
}
