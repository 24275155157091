/*
 * SPDX-FileCopyrightText: 2017 Roberto MF (Roboe)
 *
 * SPDX-License-Identifier: GPL-3.0-or-later
 */

.pc-layout {
  display: flex;
  flex-direction: column;
}

.pc-layout--header {
  height: 3.5rem;
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  justify-content: center;

  --header-bg-color: var(--brown-500);
  --header-text-color: var(--grey-50);
  --header-link-color: var(--header-text-color);

  background-color: var(--header-bg-color);
  color: var(--header-text-color);
}

.pc-layout--body {
  flex-shrink: 1;
  flex-grow: 1;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.pc-header,
.pc-main,
.pc-footer {
  width: 100%;
  max-width: 800px;
  flex-shrink: 0;
  flex-basis: auto;
}

.pc-main {
  flex-grow: 1;
}
