/*
 * SPDX-FileCopyrightText: 2017 Roberto MF (Roboe)
 *
 * SPDX-License-Identifier: GPL-3.0-or-later
 */

.pc-footer {
  width: 100%;
  height: 3.5rem;
  flex-shrink: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 0.85rem;
  font-weight: 300;

  color: var(--brown-400);
}
.pc-footer--credits,
.pc-footer--license {
  padding: 0 1em;
}

.pc-footer a {
  color: var(--main-link-color);
  text-decoration: none;
}
