/*
 * SPDX-FileCopyrightText: 2017 Roberto MF (Roboe)
 *
 * SPDX-License-Identifier: GPL-3.0-or-later
 */

.pc-header {
  padding: 0 1.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: var(--header-text-color);
}
.pc-header--title {
  font-size: 1.25rem;
  font-weight: 400;
}

.pc-header a {
  color: var(--header-link-color);
  text-decoration: none;
}
