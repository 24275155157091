/*
 * SPDX-FileCopyrightText: 2017 Roberto MF (Roboe)
 *
 * SPDX-License-Identifier: GPL-3.0-or-later
 */

.pc-text-field {
  width: 100%;
  margin: 0.5em;

  background-color: var(--grey-50);
  color: var(--main-text-color);
}
.pc-text-field,
.pc-text-field--input {
  border-radius: 0.2em;
}
.pc-text-field--label {
  padding: 0.5em 0.5em 0 0.5em;

  --label-text-color: var(--grey-600);
  color: var(--label-text-color);
}

.pc-text-field--input {
  display: block;
  width: 100%;
  line-height: 2em;
  padding: 0.25em 0.5em;
  resize: vertical;

  border: none;
  border-bottom: 2px solid grey;
}
.pc-text-field--input::selection {
  background-color: var(--brown-400);
  color: var(--grey-50);
}

.pc-text-field--button {
  border: 0;
  white-space: nowrap;

  --button-bg-color: var(--brown-500);
  background-color: var(--button-bg-color);
  color: var(--grey-50);
  border-radius: 0.2em;
  cursor: pointer;
}
.pc-text-field--button:active {
  --button-bg-color: var(--brown-400);
}

/*
  Workaround to reverse the adjacent sibling selector
  HTML order (<input> then <label>) is visually restored with the CSS order property
*/
.pc-text-field_with-label {
  display: flex;
  flex-direction: column;
}
.pc-text-field_with-label .pc-text-field--input {
  order: 2;
}
.pc-text-field_with-label .pc-text-field--label {
  order: 1;
}

/*
  With an action button on the right
*/
.pc-text-field_with-action {
  display: flex;
  flex-direction: row;
}

/*
  When <input> has the focus
*/
.pc-text-field--input:focus {
  border: none;
  border-bottom: 2px solid #a98274;
  outline: none;
}
.pc-text-field--input:focus + .pc-text-field--label {
  --label-text-color: var(--brown-400);
}
