/*
 * SPDX-FileCopyrightText: 2017 Roberto MF (Roboe)
 *
 * SPDX-License-Identifier: GPL-3.0-or-later
 */

.pc-letter-counter {
  width: 4.5rem;
  height: 1.75rem;
  margin: 0.4rem 0.8rem;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  --bg-color: var(--brown-100);
  --character-bg-color: var(--bg-color);
  --character-text-color: var(--main-text-color);
  border-radius: 0.8rem;
  background-color: var(--bg-color);
}
.pc-letter-counter--character {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin: -0.2em 0;
  flex-grow: 0;

  font-weight: 600;
  border-radius: 50%;

  background-color: var(--character-bg-color);
  color: var(--character-text-color);
}
.pc-letter-counter--number {
  flex-grow: 1;
}

/*
  State: valid
*/
.pc-letter-counter_pass {
  order: 1;
  --character-bg-color: var(--green-A700);
  --character-text-color: var(--main-text-color);
}

/*
  State: invalid
*/
.pc-letter-counter_fail {
  order: 0;
  --character-bg-color: var(--deep-orange-A700);
  --character-text-color: var(--deep-orange-50);
}
